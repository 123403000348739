window.resaltaProducto = function (selected) {
	document.getElementById(selected.id).onmouseover = function() {
		document.getElementById(selected.id).className = "list-group-item active"
	}

	document.getElementById(selected.id).onmouseout = function() {
		document.getElementById(selected.id).className = "list-group-item"
	}
};

window.itemSelectProductoOnline = function (selected) {
	var identificadorCarrito = document.getElementById("identificadorCarrito").value;
	document.getElementById("busqueda_online").value = selected.id.replaceAll("_"," ").replaceAll("_", "- ");
	window.location = '/resultadoBusquedaOnline?buscar=' + document.getElementById("busqueda_online").value + "&cart=" + identificadorCarrito;
};

window.itemSelectProductoBotonOnline = function () {
	var identificadorCarrito = document.getElementById("identificadorCarrito").value;
	var widhtg = window.innerWidth;
	var heigth = window.innerHeight;
	window.location = '/resultadoCoincidenciasOnline?buscar=' + document.getElementById("busqueda_online").value + "&cart=" + identificadorCarrito + '&widhtg=' + widhtg + "&heigth=" + heigth;
};

window.addEventListener('load', function(){
	document.getElementById("busqueda_online").addEventListener("keyup", () => {
		if((document.getElementById("busqueda_online").value.length) == 0)
			document.getElementById("resultadosProductosOnline").innerHTML = ""
		else if((document.getElementById("busqueda_online").value.length) >= 3)
			fetch('/busquedaOnline?buscar=' + document.getElementById("busqueda_online").value,{method:'get'})
		.then(response => response.text())
		.then(html     => { document.getElementById("resultadosProductosOnline").innerHTML = html})
		else
			document.getElementById("resultadosProductosOnline").innerHTML = ""
	})
})